<template>
        <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        @click:outside="closeDialog"
                        :value = "dialog"
                    >
                            <v-card ref="form">
                                <v-toolbar
                                color="primary"
                                dark
                                align = "center"
                                height="50"
                                >
                                    <v-toolbar-title>REGISTRATE</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn  icon dark @click="closeDialog">
                                                <v-icon>fas fa-times</v-icon>
                                            </v-btn>
                                        </v-toolbar-items>
                            </v-toolbar>
                                <v-card-text class="mt-3">
                                    <div class="form-group">
                                        <label class="label-registrar letra" ><b>EMAIL</b></label>
                                        <v-text-field
                                                ref="email"
                                                label="Usuario"
                                                v-model="username"
                                                type="email"
                                                placeholder="ingrese email"
                                                prepend-icon="fas fa-envelope"
                                                :rules="[rules.required_email , rules.username]"
                                                required
                                            ></v-text-field>
                                    </div>
                                    <div class="form-group">
                                        <label class="label-registrar letra" ><b>CONTRASEÑA</b></label>
                                        <v-text-field
                                                ref="pass1"
                                                type="password"
                                                v-model="pass1"
                                                placeholder="ingrese contraseña"
                                                label="Contraseña"
                                                prepend-icon="fas fa-lock"
                                                :rules ="[rules.required_pass1, rules.counter]"
                                                required
                                            >
                                            </v-text-field>
                                    </div>
                                    <div class="form-group">
                                        <label class="label-registrar letra"><b>CONFIRMAR CONTRASEÑA</b></label>
                                        <v-text-field
                                                ref="pass2"
                                                type="password"
                                                v-model="pass2"
                                                placeholder="ingrese contraseña"
                                                label="Contraseña"
                                                prepend-icon="fas fa-lock"
                                                :rules ="[rules.required_pass2, rules.required_pass2_compara]"
                                                required  
                                            >
                                            </v-text-field>
                                            <div class="alert alert-danger msg-error text-center " >
                                                    {{error}}
                                            </div>
                                    </div>
                                </v-card-text>
                                <v-card-actions class="action">
                                    <div class="modal-footer ">
                                        <v-btn block dark color="primary" :loading="loading" @click=registrar>Registrar</v-btn>

                                    </div>
                                <!-- <v-btn
                                    text
                                    @click="closeDialog"
                                >Cerrar</v-btn> -->
                                </v-card-actions>
                            </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props:{
        dialog: {
            type: Boolean,
            required: true
        },
    },

    data() {
        return {
            username:'',
            pass1:'',
            pass2:'',
            formHasErrors: false,
            loading:false,
            rules: {
                    required_email: value => !!value || 'Email Obligatorio.',
                    required_pass1: value => !!value || 'Contraseña Obligatoria.',
                    required_pass2: value => !!value || 'Confirme Contraseña.',
                    required_pass2_compara : value =>{
                        if(this.pass1 !== this.pass2){
                            return 'La contraseña no coincide'
                        }
                        else{
                            return !!value
                        }
                    },
                    counter: value => {
                        if(value.length < 8){
                            return   'Min 8 caracteres'
                        }
                        else{
                            return !!value
                        }
                    },
                    username: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Email Invalido'
                    },
            },
        }
    },
    computed: {
        ...mapState(['error']),
        form(){
            return {
                email: this.username,
                pass1: this.pass1,
                pass2: this.pass2,
            }
        },
    },

    methods: {
        ...mapActions(['registrarUsuario','VaciarError']),
        closeDialog(){
            this.$emit("update:dialog", false) 
            this.VaciarError()
            this.username = ''
            this.pass1 = ''
            this.pass2 = ''
            this.errorMessages = []
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
            this.$refs[f].reset()
            })
        },
        async registrar(){
            this.loading = true
            this.validar()
            if(!this.formHasErrors){
               await  this.registrarUsuario({email:this.username , password:this.pass1})
            }
            this.loading = false
            
        },
        validar(){
            this.formHasErrors = false
            Object.keys(this.form).forEach(f => {
                    if (!this.form[f]) this.formHasErrors = true
                    if(!this.$refs[f].validate(true)) this.formHasErrors = true
                    
            })
        }
    },
}
</script>

<style >
/* .v-toolbar__content{
    justify-content: center;
} */
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/* input:focus{
    border:3px solid rgb(20, 16, 230);
    border-color: tomato;
    margin-right:10px;
} */
.label-registrar{
    display: inline-block;
    margin-bottom: .5rem;
}
.form-group {
    margin-bottom: 1rem;
}
.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
.action{
    display: revert !important;
}

.letra{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #808080;
}
</style>