import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/DetalleVehiculo/:vehiculo',
    name: 'DetalleVehiculo',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetalleVehiculo.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/mensaje',
    name: 'Mensaje',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mensaje.vue'),
    meta : {requieresAuth: true}
  },
  {
    path: '/vehiculo',
    name: 'Vehiculo',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vehiculo.vue'),
    meta : {requieresAuth: true}
  },
  {
    path: '/editar/:id',
    name: 'Editar',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editar.vue'),
    meta : {requieresAuth: true}
  },
  {
    path: '/ver/:id',
    name: 'Ver',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ver.vue'),
    meta : {requieresAuth: true}
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(( to , from , next) => {
  if (to.path === '/'){
    next({path : '/home'})
  }
  
  if(to.matched.some(record => record.meta.requieresAuth)){
    const user = auth.currentUser
      if(user){
        next()
      }
      else{
        next({path : '/login'})
      }
  }
  else{
    next()
  }
})


export default router
