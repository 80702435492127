<template>
  <v-app>
    <v-main>
      <dashboard v-if="usuario &&  $route.name != 'Home' && $route.name != 'DetalleVehiculo' && $route.name != 'Login'"></dashboard>
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import Dashboard from './components/Dashboard.vue';

import Login from './views/Login.vue';


export default {
  name: 'App',

  components: {
    Dashboard,
    Login
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapState(['usuario'])
  },
};
</script>
