import Vue from 'vue'
import Vuex from 'vuex'
import { auth, db } from '../firebase'
import router from '../router'
import moment from 'moment'
import usados from '../modules/usados'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    usados
  },
  state: {
    usuario: null,
    error: null,
    mensajes: [],
    marcas: [],
    mensaje: {
      id: "",
      estado: "",
      fecha: "",
      apellido: "",
      nombre: "",
      email: "",
      telefono: "",
      cometario: "",
      observacion: "",
    },
    vehiculo: {
      id: "",
      anio: "",
      color: "",
      combustible: "",
      estadoRef: "",
      km: "",
      marcaRef: "",
      modelo: "",
      motor: "",
      patente: "",
      precio: "",
      puertas: "",
      descripcion: "",
      transmision: "",
      ubicacion: "",
      UrlImagenesGrandes: [],
      UrlImagenesMini: [],
      empresa: ''
    },
    vehiculos: [],
    mensajesFiltrado: [],
    vehiculosFiltrado: [],
    VehiuclosAlta: [],
    marca: "",
    estado: "",
    marcasFiltros: [],
    aniosFiltros: [],
    transmisionesFiltros: [],
    motoresFiltros: [],
    combustiblesFiltros: [],
  },
  mutations: {
    setUsuario(state, payload) {
      state.usuario = payload;
    },
    setError(state, payload) {
      if (
        payload.code === "auth/invalid-email" ||
        payload.code === "auth/wrong-password" ||
        payload.code === "auth/user-not-found" ||
        payload.code === "auth/email-already-in-use"
      ) {
        state.error = "El email o la contraseña no es valida";
      }
      if (payload === "") {
        state.error = null;
      }
    },
    setMensaje(state, payload) {
      state.mensaje = payload;
    },
    setMensajes(state, payload) {
      state.mensajes = payload;
    },
    setFiltrado(state, payload) {
      state.mensajesFiltrado = payload;
    },
    updateFiltrado(state, payload) {
      state.mensajesFiltrado = state.mensajesFiltrado.map((item) => {
        if (item.id === payload.id) {
          return (item = payload);
        } else {
          return item;
        }
      });
    },
    setMarcas(state, payload) {
      state.marcas = payload;
    },
    setVehiculos(state, payload) {
      state.vehiculos = payload;
    },
    setVehiuclosAlta(state, payload) {
      state.VehiuclosAlta = payload;
    },
    setVehiculo(state, payload) {
      state.vehiculo = payload;
    },
    setVehiculoFiltrado(state, payload) {
      state.vehiculosFiltrado = payload;
    },
    updateVehiculoFiltrado(state, payload) {
      state.vehiculosFiltrado = state.vehiculosFiltrado.map((item) => {
        if (item.id === payload.id) {
          item.estadoRef = payload.estadoRef;
          return (item = payload);
        } else {
          return item;
        }
      });
    },
    setMarcasFiltros(state, payload) {
      state.marcasFiltros = payload;
    },
    setAniosFiltros(state, payload) {
      state.aniosFiltros = payload;
    },
    setTransmisionesFiltros(state, payload) {
      state.transmisionesFiltros = payload;
    },
    setMotoresFiltros(state, payload) {
      state.motoresFiltros = payload;
    },
    setCombustiblesFiltros(state, payload) {
      state.combustiblesFiltros = payload;
    },
  },
  actions: {
    loginUsuario({ commit }, usuario) {
      auth
        .signInWithEmailAndPassword(usuario.email, usuario.password)
        .then((res) => {
          // Signed in
          const usuarioLogeado = {
            email: res.user.email,
            uid: res.user.uid,
          };
          commit("setUsuario", usuarioLogeado);
          commit("setError", "");
          router.push("/mensaje");
        })
        .catch((error) => {
          commit("setError", error);
        });
    },
    async registrarUsuario({ commit }, usuario) {
      await auth
        .createUserWithEmailAndPassword(usuario.email, usuario.password)
        .then((userCredential) => {
          var user = userCredential.user;
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          commit("setError", error);

          // ..
        });
    },
    nuevoUsuario({ commit }, usuario) {
      commit("setUsuario", usuario);
    },
    cerrarSesion({ commit }) {
      auth.signOut();
      commit("setUsuario", null);
      localStorage.setItem("filas", []);
      localStorage.setItem("filtro", []);
      router.push({ name: "Login" });
    },

    getMensajes({ commit }) {
      let mensajes = [];
      db.collection("mensajes")
        .get()
        .then((res) => {
          res.forEach((doc) => {
            let mensaje = doc.data();
            mensaje.fecha = moment
              .unix(doc.data().fecha.seconds)
              .format("DD/MM/YYYY");
            mensaje.id = doc.id;
            if (mensaje.proviene == "Dycar") {
              mensajes.push(mensaje);
            }
          });
          commit("setMensajes", mensajes);
        });
    },
    getMensaje({ commit, state }, id) {
      db.collection("mensajes")
        .doc(id)
        .get()
        .then((res) => {
          let mensaje = res.data();
          mensaje.id = res.id;
          commit("setMensaje", mensaje);
        });
    },
    EstadoRespondido({ commit, state }, item) {
      /*Cambia el estado del  Mensaje */
      db.collection("mensajes")
        .doc(item.id)
        .update({
          estado: "Respondido",
        })
        .then(() => {
          commit("updateFiltrado", item);
        })
        .catch((error) => {
        });
    },

    GuardarCambioMensaje({ commit, dispatch }, item) {
      db.collection("mensajes")
        .doc(item.id)
        .update({
          estado: item.estado,
          observacion: item.observacion,
        })
        .then(() => {
          dispatch("getMensajes");
          commit("updateFiltrado", item);
        })
        .catch((error) => {
        });
    },

    aplicarFiltro({ commit, state }, filtro) {
      let filtrado = state.mensajes.filter((mensaje) => {
        return (
          (filtro.nombre.trim() === "" ||
            mensaje.nombre.trim().toLowerCase() ===
              filtro.nombre.trim().toLowerCase()) &&
          (filtro.apellido.trim() === "" ||
            mensaje.apellido.trim().toLowerCase() ===
              filtro.apellido.trim().toLowerCase()) &&
          (filtro.estado === "" || mensaje.estado === filtro.estado)
        );
      });
      commit("setFiltrado", filtrado);
    },

    async getVehiculos({ commit, state }) {
      let vehiculos = [];
      await db
        .collection("vehiculos")
        .get()
        .then((res) => {
          res.forEach((doc) => {
            let vehiculo = doc.data();
            vehiculo.id = doc.id;
            vehiculos.push(vehiculo);
          });
          commit("setVehiculos", vehiculos);
        });
    },

    // Refactorizado
    aplicarFiltroVehiculo({ commit, state }, filtro) {
      let filtrado = state.vehiculos.filter((vehiculo) => {
        return (
          (filtro.marca === "" || vehiculo.marcaRef.id === filtro.marca.id) &&
          (filtro.modelo === "" ||
            vehiculo.modelo.trim() === filtro.modelo.trim()) &&
          (filtro.anio === "" || vehiculo.anio === parseInt(filtro.anio, 10)) &&
          (filtro.estado === "" ||
            vehiculo.estadoRef.id === filtro.estado.id) &&
          (filtro.patente === "" ||
            vehiculo.patente
              .trim()
              .toLowerCase()
              .includes(filtro.patente.trim().toLowerCase()))
        );
      });
      commit("setVehiculoFiltrado", filtrado);
    },

    async darBajaVehiculo({ commit }, vehiculo) {
      await db
        .collection("vehiculos")
        .doc(vehiculo.id)
        .update({
          estadoRef: vehiculo.estadoRef,
        })
        .then(() => {
          commit('updateVehiculoFiltrado', vehiculo)
        })
        .catch((error) => {
        });
    },
    async darAltaVehiculo({ commit }, vehiculo) {
      await db
        .collection("vehiculos")
        .doc(vehiculo.id)
        .update({
          estadoRef: vehiculo.estadoRef,
        })
        .then(() => {
          commit("updateVehiculoFiltrado", vehiculo);
        });
    },

    async getVehiculo({ commit }, id) {
      await db
        .collection("vehiculos")
        .doc(id)
        .get()
        .then((res) => {
          let vehiculo = res.data();
          vehiculo.id = res.id;
          commit("setVehiculo", vehiculo);
        });
    },

    async editarVehiculo({ commit }, vehiculo) {
      await db.collection('vehiculos').doc(vehiculo.id).update({
        estadoRef: vehiculo.estadoRef,
        marcaRef: vehiculo.marcaRef,
        modelo: vehiculo.modelo,
        anio: vehiculo.anio,
        km: vehiculo.km,
        patente: vehiculo.patente,
        puertas: vehiculo.puertas,
        transmision: vehiculo.transmision,
        color: vehiculo.color,
        combustible: vehiculo.combustible,
        motor: vehiculo.motor,
        precio: vehiculo.precio,
        ubicacion: vehiculo.ubicacion,
        descripcion: vehiculo.descripcion,
        empresa: vehiculo.empresa
      })
        .then(res => {
          commit('setVehiculo', vehiculo)
        })
        .then((res) => {
          commit("setVehiculo", vehiculo);
        });
    },

    async getVehiculosAlta({ commit }) {
      let respuesta = await db.collection("vehiculos").get();
      let vehiculoA = [];
      for (let index = 0; index < respuesta.docs.length; index++) {
        let vehiculo = respuesta.docs[index].data();
        vehiculo.id = respuesta.docs[index].id;
        if (vehiculo.estadoRef.id === "1") {
          vehiculoA.push(vehiculo);
        }
      }
      commit("setVehiuclosAlta", vehiculoA);
    },

    VaciarError({ commit }) {
      commit("setError", "");
    },

    marcasFiltrosAltas({ commit }, marcas) {
      commit("setMarcasFiltros", marcas);
    },

    aniosFiltrosAltas({ commit }, anios) {
      commit("setAniosFiltros", anios);
    },

    transmisionesFiltrosAltas({ commit }, transmisiones) {
      commit("setTransmisionesFiltros", transmisiones);
    },

    motoresFiltrosAltas({ commit }, motores) {
      commit("setMotoresFiltros", motores);
    },

    combustiblesFiltrosAltas({ commit }, combustibles) {
      commit("setCombustiblesFiltros", combustibles);
    },

    async getMarcas({ commit }) {
      let respuesta = await db.collection("marcas").get();
      let marcas = [];
      for (let index = 0; index < respuesta.docs.length; index++) {
        let marca = respuesta.docs[index].data();
        marca.id = respuesta.docs[index].id;
        marcas.push(marca);
      }
      commit("setMarcas", marcas);
    },

    async getVehiculoPorPatente({ commit }, patente) {
      try {
        const vehiculosRef = db.collection("vehiculos");
        const querySnapshot = await vehiculosRef
          .where("patente", "==", patente)
          .get();

        if (querySnapshot.empty) {
          return null;
        }

        const vehiculos = [];
        querySnapshot.forEach((doc) => {
          vehiculos.push({ id: doc.id, ...doc.data() });
        });

        return vehiculos[0]; 
      } catch (error) {
        throw error;
      }
    },
  },
});
