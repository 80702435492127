import { db } from '../firebase';

export default {
  namespaced: true,
  actions: {

    async getVehiculosUsados() {
      try {
        let refBaja = db.collection('estados').doc('1');
        const querySnapshot = await db.collection('vehiculos').where('estadoRef', '==', refBaja).get();
        const vehiculos = [];

        querySnapshot.forEach((doc) => {
          const vehiculo = doc.data();
          vehiculo.id = doc.id;
          vehiculos.push(vehiculo);
        });
        return {
          exito: 1,
          data: vehiculos
        }
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo getVehiculosUsados: " +
            error.message,
        };
      }
    },


    async getMarcasUsados() {
      try {
        let marcas = []
        const querySnapshot = await db.collection('marcas').get();
        querySnapshot.forEach((doc) => {
          marcas.push({
            ...doc.data(),
            id: doc.id
          })
        });

        return {
          exito: 1,
          data: marcas
        }
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo getMarcasUsados: " +
            error.message,
        };
      }
    },

    async getPrecios() {
      try {
        let filtro_precio = {}
        const querySnapshot = await db.collection('filtro_precio').get();
        querySnapshot.forEach((doc) => {
          filtro_precio = doc.data()
        });

        return {
          exito: 1,
          data: filtro_precio
        }
      } catch (error) {
        return {
          exito: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo getPrecios: " +
            error.message,
        };
      }
    }
  }
}