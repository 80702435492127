import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/js';
import '@mdi/font/css/materialdesignicons.css'
import { firebase, auth } from './firebase'
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';

Vue.use(VueLoaders);

Vue.config.productionTip = false
auth.onAuthStateChanged(user => {
  if (user) {
    const detectoUsuario = {
      email : user.email,
      uid : user.uid
    }
    store.dispatch('nuevoUsuario',detectoUsuario);
  } 
  else{
    store.dispatch('nuevoUsuario',user)
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});


