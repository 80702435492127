<template>
<div>
    <v-card
    class="mx-auto overflow-hidden"
    >
        <v-app-bar
            app
            color="primary"
            dark
            height="68"
            rounded="0"
            
            >
                <v-spacer></v-spacer>

                <v-btn target="_blank"  text   @click="cerrarSesion" >
                    <span class="mr-2" >Cerrar Sesion</span>
                    <v-icon small>fas fa-user-alt</v-icon>
                </v-btn>
        </v-app-bar>
       <v-navigation-drawer 
        color="primary" 
        v-model="drawer"
        :mini-variant.sync="mini"
        hide-overlay
        stateless  
       app >
                <v-list-item>
                    <v-list-item-icon>
                            <v-btn 
                                icon
                                @click.stop="mini = !mini"
                                >
                                    <v-icon class="white--text mr-2">fas fa-bars</v-icon>
                            </v-btn>
                    </v-list-item-icon>
                    <v-list-item-content>
                                           <v-list-item-title class=" white--text ">Menu</v-list-item-title>
                    </v-list-item-content>
                    
            </v-list-item>
            <!-- <v-divider></v-divider> -->
            <v-list>
                    <v-list-group  color="white"  no-action  >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                            <v-icon class="white--text">fas fa-user-alt</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="white--text">Usuario</v-list-item-title>
                        </template>
                        <v-list-item link @click="dialog=true">
                            <v-list-item-content>
                                            <v-list-item-title class=" white--text " style="font-size:13px">Cambiar Contraseña</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-for="item in items" :key="item.title" link :to="item.to" @click="cambiar" >
                            <v-list-item-icon>
                                            <v-icon class="white--text">{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                            <v-list-item-title class="white--text">{{item.title}}</v-list-item-title>
                            </v-list-item-content>
                    </v-list-item>
                    
            </v-list>
        </v-navigation-drawer>
    </v-card>
    <v-dialog v-model="dialog"  max-width="500px">
                <v-card>
                      <v-toolbar dark color="primary" style="height: 53px;">
                            <v-toolbar-title>Cambiar Contraseña</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                  <v-btn icon dark small  @click="closeDialog()">
                                      <v-icon small>fas fa-times</v-icon>
                                  </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>
                            <v-card-text>
                                <v-container>
                                    <v-form  ref="form"  lazy-validation>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <h3>Usuario</h3>
                                                    <v-text-field
                                                    placeholder="Email"
                                                    required
                                                    v-model="email"
                                                    type="email"
                                                    :rules="[rules.requiredEmail , rules.email]"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <h3>Contraseña Actual</h3>
                                                    <v-text-field
                                                    placeholder="Contraseña Actual"
                                                    :type="show1 ? 'text' : 'password'"
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="show1 = !show1"
                                                    v-model="pass_old"
                                                    :rules="[ rules.required_pass_old]"  
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <h3>Nueva Contraseña</h3>
                                                    <v-text-field
                                                    placeholder="Contraseña nueva"
                                                    required
                                                    v-model="pass1"
                                                    :type="show2 ? 'text' : 'password'"
                                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="show2 = !show3"
                                                    :rules ="[rules.required_pass1]"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <h3>Confirmar Contraseña</h3>
                                                    <v-text-field
                                                    placeholder="Confirmar contraseña "
                                                    v-model="pass2"
                                                    :type="show3 ? 'text' : 'password'"
                                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="show3 = !show3"  
                                                    :rules ="[rules.required_pass2, rules.required_pass2_compara]"                                          ></v-text-field>
                                                </v-col>
                                            </v-row>
                                    </v-form>
                                </v-container>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog()"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="CambiarPass(pass1)"
                        >
                            Confirmar
                        </v-btn>
                        </v-card-actions>
                </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialog_confir"
        max-width="290"
    >
      <v-card>
            <v-card-title class="headline">Mensaje</v-card-title>

            <v-card-text class="text-center">
                Se modifico la contraseña con exito !!.
            </v-card-text>

        <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog_confir = false"
                >
                    OK
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog_error"
        max-width="290"
    >
      <v-card>
            <v-card-title class="headline">Mensaje</v-card-title>

            <v-card-text class="text-center">
                Ocurrio un problema , intente nuevamente .
            </v-card-text>

        <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog_error = false"
                >
                    OK
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_loader"
      hide-overlay
      persistent
      width="300"
    >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                Espere un momento ...
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import {auth , db } from '../firebase'
import firebase from 'firebase/app'
export default {
    data () {
        return {
            drawer: true,
            items: [
                { title: 'Mensaje', icon: 'fas fa-envelope' ,to:{name:'Mensaje'}},
                { title: 'Vehiculos', icon: 'fas fa-car' ,to:{name:'Vehiculo'}}
            ],
            mini : true,
            dialog:false,
            dialog_confir:false,
            dialog_error:false,
            dialog_loader:false,
            show1:false,
            show2:false,
            show3:false,
            email:'',
            pass_old:'',
            pass1:'',
            pass2:'',
            rules: {
                    required_pass_old: value => !!value || 'Contraseña Obligatoria.',
                    required_pass1: value => !!value || 'Contraseña Obligatoria.',
                    required_pass2: value => !!value || 'Confirme Contraseña.',
                    requiredEmail : value => !! value || 'Email Obligatorio',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Email Invalido'
                    },
                    required_pass2_compara : value =>{
                        if(this.pass1 !== this.pass2){
                            return 'La contraseña no coincide'
                        }
                        else{
                            return !!value
                        }
                    },
                    // counter: value => {
                    //     if(value.length < 6){
                    //         return   'Min 6 caracteres'
                    //     }
                    //     else{
                    //         return !!value
                    //     }
                    // },
            },
        }
  },
  methods: {
      ...mapActions(['cerrarSesion']),
      cambiar(){
          if(!this.mini){
            this.mini = true
          }    
      },

    validar(){
            return this.$refs.form.validate()
    },

    async CambiarPass(newPassword){
        let credenciales = firebase.auth.EmailAuthProvider.credential(this.email,this.pass_old)
        let respuesta = auth.currentUser.reauthenticateWithCredential(credenciales)
        if(this.validar()){
            this.dialog_loader = true
            await auth.currentUser.updatePassword(newPassword.toString())
            .then(() =>{
                this.dialog_loader = false
                // this.dialog= fasle
                this.dialog_confir=true
                this.$refs.form.resetValidation()
                this.$refs.form.reset()
                

            })
            .catch(() => {
                this.dialog_loader = false
                this.dialog_error = true
                this.$refs.form.resetValidation()
                this.$refs.form.reset()  
            })

            this.dialog= false
        }
    },

      closeDialog(){
            this.dialog=false 
            this.$refs.form.reset()
            this.$refs.form.resetValidation()   
      }

  },

  created() {
  },
}
</script>

<style>
/* .theme--light.v-icon {
    color: white !important;
} */
</style>