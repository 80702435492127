<template>
        <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        @click:outside="closeDialog"
                        :value = "dialog"
                    >
                            <v-card ref="form" flat>
                                <v-toolbar
                                color="primary"
                                dark
                                align = "center"
                                height="50"
                                >
                                    <v-toolbar-title>RECUPERAR CONTRASEÑA</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn  icon dark @click="closeDialog">
                                                <v-icon>fas fa-times</v-icon>
                                            </v-btn>
                                        </v-toolbar-items>
                            </v-toolbar>
                                <v-card-text class="mt-3">
                                    <div class="form-group">
                                        <label class="label-registrar letra" ><b>EMAIL</b></label>
                                        <v-text-field
                                                ref="email"
                                                label="Usuario"
                                                v-model="username"
                                                type="email"
                                                placeholder="ingrese email"
                                                prepend-icon="fas fa-envelope"
                                                :rules="[rules.required_email , rules.username]"
                                                required
                                            ></v-text-field>
                                    </div>
                                </v-card-text>
                                <v-card-actions class="action">
                                    <div class="modal-footer ">
                                        <v-btn block dark color="primary" :loading="loading" @click=recuperar>Recuperar contraseña</v-btn>
                                    </div>
                                </v-card-actions>
                            </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {db , auth } from '../firebase'
import Swal from 'sweetalert2'
export default {
    props:{
        dialog: {
            type: Boolean,
            required: true
        },
    },

    data() {
        return {
            username:'',
            formHasErrors: false,
            loading:false,
            error_recuperar:false,
            rules: {
                    required_email: value => !!value || 'Email Obligatorio.',
                    username: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Email Invalido'
                    },
            },
        }
    },
    computed: {
        ...mapState(['error']),
        form(){
            return {
                email: this.username,

            }
        },
    },

    methods: {
        ...mapActions(['VaciarError']),
        closeDialog(){
            this.$emit("update:dialog", false) 
            this.VaciarError()
            this.username = ''
            this.errorMessages = []
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
            this.$refs[f].reset()
            })
        },
        async recuperar(){
            const Swal = require('sweetalert2')
            this.loading = true
            this.validar()
            if(!this.formHasErrors){
                await this.recuperarPass(this.username)
                if(this.error_recuperar == false){
                    await Swal.fire({
                        title: 'Se ah enviado un email a su cuenta',
                        confirmButtonColor:'#1867c0',
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        }
                    })
                }
                else{
                    await Swal.fire({
                            title: 'No se ah podido enviar el email, intente nuevamente',
                            confirmButtonColor:'#1867c0',
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            },
                            icon:'warning'
                        })
                }
            }
            this.loading = false
            
        },
        validar(){
            this.formHasErrors = false
            Object.keys(this.form).forEach(f => {
                    if (!this.form[f]) this.formHasErrors = true
                    if(!this.$refs[f].validate(true)) this.formHasErrors = true
                    
            })
        },
     async recuperarPass(email){

         await auth.sendPasswordResetEmail(email)
         .then(()=>{
             this.error_recuperar = false
         })
         .catch(()=>{
             this.error_recuperar = true
         })
     }
    },
}
</script>

<style >
/* .v-toolbar__content{
    justify-content: center;
} */
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/* input:focus{
    border:3px solid rgb(20, 16, 230);
    border-color: tomato;
    margin-right:10px;
} */
.label-registrar{
    display: inline-block;
    margin-bottom: .5rem;
}
.form-group {
    margin-bottom: 1rem;
}
.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
.action{
    display: revert !important;
}

.letra{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #808080;
}
</style>