<template>
    <v-container class="fondo" fluid>
        <v-row align="center" justify="center" style="margin-top:10px">
            <v-col xs ="12" sm="12"  md="5">
                    <v-card class="opaco" max-height="alto" max-width="ancho" style="opacity:90% !important">
                        <v-card-title  style="margin-bottom: -15px !important;justify-content:center !important">
                            <v-row align="center" justify="center" style="margin-top:10px">
                                <v-col cols="12" sm="6" class="d-flex py-sm-3 py-0">
                                    <v-img
                                        contain
                                        height="62"
                                        width="156"
                                        src="../assets/dycar-logo.webp"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12" sm="6" class="d-flex pt-sm-3 pt-0">
                                    <v-img
                                        contain
                                        height="45"
                                        width="222"
                                        src="../assets/eurodycar.webp"
                                    ></v-img>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="ProcesarLogin">
                                <v-container>
                                    <v-row >
                                        <v-col cols ="12">
                                            <v-text-field
                                                label="Usuario"
                                                type="email"
                                                v-model="username"
                                                placeholder="ingrese email"
                                                prepend-icon="fas fa-envelope"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                type="password"
                                                v-model="passuser"
                                                placeholder="ingrese contraseña"
                                                label="Contraseña"
                                                prepend-icon="fas fa-lock"
                                                required
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="error !== null">
                                        <v-col cols="12">
                                            <div class="alert alert-danger msg-error text-center " >
                                                    {{error}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn type="submit" block dark  color="primary" :loading="loading" >Ingresar</v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <!-- <v-col cols="12" sm="12"  md="6"   xs="12">
                                            <v-btn v-ripple="false" text small class=" botones" @click=" registrar=true">Registrate</v-btn>
                                            <RegistrarUsuario :dialog.sync="registrar"/>
                                        </v-col> -->
                                        <v-col cols="12" sm="6"  md="6" lg="4">
                                            <div >
                                                <v-btn v-ripple="false" text small class="botones" @click="olvidado=true">¿Olvidó su contraseña?</v-btn>
                                                <PassOlvidada :dialog.sync="olvidado" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>
                    </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import RegistrarUsuario from '../components/RegistrarUsuario'
import PassOlvidada from '../components/PassOlvidada.vue'

export default {
  components: { RegistrarUsuario ,PassOlvidada },
    data() {
        return {
            passuser:'',
            username:'',
            loading :false,
            registrar:false,
            olvidado:false
        }
    },
    methods: {
        ...mapActions(['loginUsuario','VaciarError']),
        async ProcesarLogin(){
            try {
                this.loading = true
                this.VaciarError()
                await this.loginUsuario({email:this.username , password: this.passuser })
                // this.loading = false
            } catch (error) {
            }
            
            
        }
       
    },
    computed: {
        ...mapState(['error']),
        alto(){
            if(screen.width < 1024){
                return "430"
            }
            else{
                return "360"
            }
        }
    },

    watch: {
        error:function(){
            if(this.error != null){
                this.loading = false
            }
        }
    },

   
}
</script>

<style scoped>

.fondo {
    background-image: url("../assets/fondo_login.webp");
    background-size: cover;
    width: 100%;
    /* padding: 12px; */
    /* margin-right: auto; */
    /* margin-left: auto; */
    height: 100%;   
}
.opaco{
    opacity: 90% !important
}
.titulo{
    text-align:center; 
    color:rgb(81, 40, 218);
    font-family: Vegur, 'PT Sans', Verdana, sans-serif;
    font-size: 550%;
}
.msg-error{
    color:#ef0707;
}
.botones{
    color:#423b3b !important;
    margin-left: -55px;
     padding: 0% !important; 
}
.botones::before{
    background-color: transparent !important;
    
}
.botones:hover{
    color: #2067b7  !important;
}
.margenes{
    margin: -40px !important;
}

</style>